import * as SentryLib from '@sentry/browser';
import { BaseTransportOptions, TransportRequest } from '@sentry/types';
import { rejectedSyncPromise } from '@sentry/utils';

export const makeSentry10Transport = (options: BaseTransportOptions) => {
	const transportSentry10 = async (request: TransportRequest) => {
		
		const sentryWorkerPayload = {
			envelope: request.body,
			url: options.url,
		};

		try {
			const host = (process as any).env.CONTRAIL_WORKER_HOST;
			const response = await fetch(`${host}/sentry/envelope`, {
				method: 'POST',
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(sentryWorkerPayload),
			});

			return {
				statusCode: response.status,
				headers: {
					'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
					'retry-after': response.headers.get('Retry-After'),
				},
			};
		} catch (err) {
			return rejectedSyncPromise(err);
		}
	};

	return SentryLib.createTransport(options, transportSentry10);
};
(globalThis as any).Sentry = {
	...SentryLib,
	init: function (options: BaseTransportOptions) {
		SentryLib.init({
			transport: (options) => makeSentry10Transport(options),
			...options,
		});
	},
};
